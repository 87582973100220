import {
  Button,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Upload,
  message,
  DatePicker,
} from "antd";
import { useUnit } from "effector-react";
import {
  $showCreateModal,
  $userListPlaceId,
  changeShowCreateModalEv,
  createUserFx,
} from "../../models/userListModel";
import { UploadOutlined } from "@ant-design/icons";
import style from "./ClientUserList.module.css";
import { useState } from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

const selectOptions = [
  { label: "Сотрудник", value: "Сотрудник" },
  { label: "Уволен", value: "Уволен" },
  { label: "Потенциальный сотрудник", value: "Потенциальный сотрудник" },
];

const genderOptions = [
  { label: "Мужчина", value: "Male" },
  { label: "Женщина", value: "Female" },
];

export default function CreateUserModal() {
  const showCreateModal = useUnit($showCreateModal);
  const changeShowCreateModal = useUnit(changeShowCreateModalEv);
  const userListPlaceId = useUnit($userListPlaceId);
  const loading = useUnit(createUserFx.pending);

  const [form] = Form.useForm();
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [uploadedPhotoBase64, setUploadedPhotoBase64] = useState(null);

  const onClose = () => {
    setTimeout(() => {
      changeShowCreateModal(false);
      form.resetFields();
      setUploadedPhotoBase64(null);
    }, 500);
  };

  const handlePhotoUpload = ({ fileList }) => {
    if (fileList.length > 0) {
      const file = fileList[0].originFileObj || fileList[0];

      // Reject images larger than 300KB
      if (file.size > 300 * 1024) {
        message.error("Файл слишком большой! Максимальный размер: 300KB.");
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        let base64 = e.target.result;

        // Reduce Base64 length
        if (base64.length > 150000) {
          base64 = base64.substring(0, 150000) + "...(truncated)";
          message.warning("⚠️ Фото слишком большое, оно было обрезано.");
        }

        setUploadedPhotoBase64(base64);
        message.success("Фото успешно загружено!");
      };
      reader.readAsDataURL(file);
    } else {
      setUploadedPhotoBase64(null);
      message.info("Фото удалено.");
    }
  };

  const onFinish = (values) => {
    if (!uploadedPhotoBase64) {
      message.error(
        "⚠️ Пожалуйста, загрузите фото перед добавлением сотрудника."
      );
      return;
    }

    const payload = {
      place_id: userListPlaceId,
      properties: {
        emp_department: values.emp_department,
        emp_position: values.emp_position,
        start_work_time: values.start_work_time || "09:00",
        end_work_time: values.end_work_time || "18:00",
        user_name: values.user_name,
        user_surname: values.user_surname,
        emp_category: values.emp_category,
        user_gender: values.user_gender,
        user_mobile: values.user_mobile || "",
        user_date_of_birth: values.user_date_of_birth || "",
      },
      user_name: values.user_name,
      user_surname: values.user_surname,
      data_url: uploadedPhotoBase64 ? [uploadedPhotoBase64] : [],
    };

    console.log("📤 Sending Payload:", JSON.stringify(payload, null, 2));

    createUserFx(payload);
  };

  return (
    <Modal
      open={showCreateModal}
      onCancel={onClose}
      title={"Добавить сотрудника"}
      onOk={form.submit}
      footer={null}
    >
      <Form
        form={form}
        onFinish={onFinish}
        layout={"vertical"}
        style={{ marginTop: "16px" }}
        onValuesChange={(changed, all) => {
          setSaveDisabled(Object.values(all).some((item) => !item));
        }}
      >
        <Form.Item label="Фото сотрудника (максимум 300 KB)">
          <Upload
            name="photo"
            listType="picture"
            maxCount={1}
            beforeUpload={() => false}
            onChange={handlePhotoUpload}
            accept="image/*"
          >
            <Button icon={<UploadOutlined />}>Загрузить фото</Button>
          </Upload>
          {uploadedPhotoBase64 && (
            <div style={{ marginTop: 10 }}>
              <img
                src={uploadedPhotoBase64}
                alt="Preview"
                style={{
                  width: "100px",
                  height: "100px",
                  objectFit: "cover",
                  borderRadius: "8px",
                  border: "1px solid #ddd",
                }}
              />
            </div>
          )}
        </Form.Item>

        <Form.Item
          name="user_name"
          label="Имя"
          rules={[{ required: true, message: "Пожалуйста, введите имя" }]}
        >
          <Input placeholder="Введите имя" />
        </Form.Item>

        <Form.Item
          name="user_surname"
          label="Фамилия"
          rules={[{ required: true, message: "Пожалуйста, введите фамилию" }]}
        >
          <Input placeholder="Введите фамилию" />
        </Form.Item>

        <Form.Item
          name="emp_department"
          label="Департамент"
          rules={[
            { required: true, message: "Пожалуйста, введите департамент" },
          ]}
        >
          <Input placeholder="Департамент" />
        </Form.Item>

        <Form.Item
          name="emp_position"
          label="Должность"
          rules={[{ required: true, message: "Пожалуйста, введите должность" }]}
        >
          <Input placeholder="Введите должность сотрудника" />
        </Form.Item>

        <Form.Item
          name="emp_category"
          label="Статус"
          rules={[{ required: true, message: "Пожалуйста, выберите статус" }]}
        >
          <Select options={selectOptions} placeholder="Выбрать статус" />
        </Form.Item>

        <Form.Item
          name="user_gender"
          label="Пол"
          rules={[{ required: true, message: "Пожалуйста, выберите пол" }]}
        >
          <Radio.Group>
            <Radio value="Female">Ж</Radio>
            <Radio value="Male">М</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>

      <Form.Item
        label="Дата рождения"
        name="user_date_of_birth"
        rules={[
          {
            required: false,
            message: "Пожалуйста, выберите или введите дату рождения",
          },
          {
            validator: (_, value) => {
              if (!value) {
                return Promise.reject("Пожалуйста, выберите или введите дату.");
              }
              if (!dayjs(value, "DD.MM.YYYY", true).isValid()) {
                return Promise.reject("Некорректный формат даты (DD.MM.YYYY).");
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <DatePicker
          format="DD.MM.YYYY"
          placeholder="Введите или выберите дату"
          style={{ width: "100%" }}
          onChange={(date) => form.setFieldsValue({ date_of_birth: date })}
          onBlur={(e) => {
            const enteredDate = e.target.value;
            if (dayjs(enteredDate, "DD.MM.YYYY", true).isValid()) {
              form.setFieldsValue({
                date_of_birth: dayjs(enteredDate, "DD.MM.YYYY"),
              });
            } else if (enteredDate) {
              message.error("Некорректный формат даты. Используйте DD.MM.YYYY");
            }
          }}
          disabledDate={(current) => current && current > dayjs().endOf("day")}
        />
      </Form.Item>
      <Form.Item
        name="user_mobile"
        label="Номер телефона"
        rules={[
          { required: false, message: "Пожалуйста, введите номер телефона" },
        ]}
      >
        <Input placeholder="Введите номер телефона" />
      </Form.Item>

      <Button
        onClick={form.submit}
        className={style.modal_button}
        type="primary"
        style={{ marginRight: "8px" }}
        disabled={saveDisabled}
        loading={loading}
      >
        Сохранить
      </Button>
      <Button onClick={onClose} className={style.modal_button}>
        Отмена
      </Button>
    </Modal>
  );
}
