import { createEffect } from "effector";
import { notification } from "antd";

export const getUserListFx = createEffect().use(async (payload) => {
  try {
    console.log("getUserListFx called with payload:", payload);

    const response = await fetch(
      "https://5zbcsztftk.execute-api.eu-west-1.amazonaws.com/default/prod-user-list-api",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sort_key: payload ? payload : "amaa25d7c9",
          emp_category: "Сотрудник",
        }),
      }
    );

    console.log("API Response Status:", response.status);
    let result = await response.json();
    console.log("API Response Body:", result);

    const tempRes = JSON.parse(result.body);
    console.log("getUserListFx:: Parsed body", tempRes);

    return tempRes;
  } catch (e) {
    console.error("getUserListFx Error:", e);
    return [];
  }
});

export const sendEditUserFx = createEffect().use(async (payload) => {
  try {
    if (!payload.partition_key || !payload.sort_key) {
      throw new Error("Missing required fields in payload");
    }

    const response = await fetch(
      "https://i8wkv4zm9k.execute-api.eu-west-1.amazonaws.com/ChangeInfoOnEmployees/EmployeeInfo",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "update",
          values: {
            user_id: payload.partition_key,
            place_id: payload.sort_key,
            start_work_time: payload.start_work_time,
            end_work_time: payload.end_work_time,
            emp_department: payload.emp_department,
            emp_position: payload.emp_position,
            user_name: payload.user_name,
            user_surname: payload.user_surname,
            emp_category: payload.emp_category,
            user_gender: payload.user_gender,
          },
        }),
      }
    );

    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

    let result = await response.json();
    console.log("sendEditUserFx:: result", result);

    console.log("API Response:", result);

    if (result.statusCode === 200) {
      notification.success({
        message: "Success",
        description: "Данные сотрудника успешно отредактированы!",
        placement: "topRight",
      });
      return result.body;
    } else {
      throw new Error(result.body || "Unknown server error");
    }
  } catch (error) {
    console.error("Data is updated, but there is some error:", error);
    notification.error({
      message: "Error",
      description:
        error.message ||
        "Data is updated, but something went wrong on server...",
      placement: "topRight",
    });
  }
});

export const getPotentialEmpFx = createEffect().use(async (payload) => {
  try {
    const response = await fetch(
      "https://5zbcsztftk.execute-api.eu-west-1.amazonaws.com/default/GetPotentialEmployeesByPlaceId",
      {
        method: "POST",
        body: JSON.stringify({
          client_id: payload ? payload : "amaa25d7c9",
        }),
      }
    );

    let result = await response.json();
    return result.data;
  } catch (e) {
    console.error(e);
  }
});

export const createEmpFx = createEffect().use(async (payload) => {
  try {
    const response = await fetch(
      "https://i8wkv4zm9k.execute-api.eu-west-1.amazonaws.com/ChangeInfoOnEmployees/EmployeeInfo",
      {
        method: "POST",
        body: JSON.stringify({
          action: "create_from_potential",
          values: payload,
        }),
      }
    );

    let result = await response.json();
    console.log("sendEditUserFx:: resiult", result);
    if (result.statusCode === "200") {
      notification.success({
        message: "Success",
        description: "User successfully changed",
        placement: "topRight",
        maxCount: 1,
      });
    } else {
      notification.error({
        message: "Error",
        description: "Something went wrong...",
        placement: "topRight",
        maxCount: 1,
      });
    }
    return result.body;
  } catch (e) {
    console.error(e);
  }
});

export const createUserFx = createEffect().use(async (payload) => {
  try {
    console.log("📤 Sending API Request:", JSON.stringify(payload, null, 2));

    const response = await fetch(
      "https://ste1h7g7s7.execute-api.eu-west-1.amazonaws.com/LoyaltySystem1/UserRecords",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      }
    );

    const rawResponse = await response.text();
    console.log("📥 Raw API Response:", rawResponse);

    if (!response.ok || !rawResponse) {
      throw new Error(
        `⚠️ API Error: ${response.status} - ${rawResponse || "Empty response"}`
      );
    }

    let result;
    try {
      result = JSON.parse(rawResponse);
    } catch (error) {
      throw new Error(`❌ Failed to parse JSON response: ${rawResponse}`);
    }

    console.log("✅ Parsed API Response:", result);

    if (result?.statusCode === 200) {
      notification.success({
        message: "✅ Успех",
        description: result.body || "Пользователь успешно создан!",
        placement: "topRight",
        maxCount: 1,
      });
      return result.body;
    } else {
      throw new Error(result?.body || "⚠️ Неизвестная ошибка сервера");
    }
  } catch (e) {
    console.error("❌ createUserFx Error:", e.message);
    notification.error({
      message: "❌ Ошибка",
      description: e.message || "Неизвестная ошибка",
      placement: "topRight",
      maxCount: 1,
    });
    return null;
  }
});

export const createEduUserFx = createEffect().use(async (payload) => {
  try {
    const response = await fetch(
      "https://ste1h7g7s7.execute-api.eu-west-1.amazonaws.com/LoyaltySystem1/UserRecords",
      {
        method: "POST",
        body: JSON.stringify(payload),
      }
    );

    let result = await response.json();
    console.log("createEduUserFx:: result", result);
    return true;
  } catch (e) {
    console.error(e);
  }
});

export const getUserListEduFx = createEffect().use(
  async ({ userGroups, emp_category }) => {
    try {
      console.log(
        "📤 Fetching list for:",
        userGroups,
        "Category:",
        emp_category
      );

      const schoolSortKeys = {
        Aubakirova: ["zasa05e1p1"], // Only one place
        Pifagor:
          emp_category === "Сотрудник"
            ? ["phsa10m6f1"] // Employees from ONE place
            : ["phsa10m6f1", "pmsa10h3p6"], // Students from BOTH places
      };

      const sortKeys =
        schoolSortKeys[
          userGroups.includes("Pifagor") ? "Pifagor" : "Aubakirova"
        ];

      let allUsers = [];
      for (let sortKey of sortKeys) {
        console.log(`📡 Fetching data from sort_key: ${sortKey}`);

        const response = await fetch(
          "https://5zbcsztftk.execute-api.eu-west-1.amazonaws.com/default/GetUserListByPlaceIdEDU",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              sort_key: sortKey,
              emp_category,
            }),
          }
        );

        if (!response.ok) {
          throw new Error(`API Error: ${response.status}`);
        }

        let result = await response.json();
        if (result?.body) {
          const parsedData = JSON.parse(result.body);
          console.log(
            `✅ Received ${parsedData.length} records from ${sortKey}`
          );
          allUsers = [...allUsers, ...parsedData]; // Combine results
        }
      }

      return allUsers;
    } catch (e) {
      console.error("❌ API Error:", e.message);
      return [];
    }
  }
);

export const sendImportFilesFx = createEffect().use(async (payload) => {
  try {
    console.log("payload", payload);
    const formData = new FormData();
    formData.append("archive", payload.archive, "archive.zip");
    formData.append("excel", payload.excel, "excel_file.xlsx");
    formData.append("place_id", payload.place_id);
    const response = await fetch(payload.link, {
      method: "POST",
      body: formData,
    });
    let result = await response.json();
    return result.result;
  } catch (e) {
    console.error(e);
  }
});

export const getEduTemplateFx = createEffect().use(async () => {
  try {
    const response = await fetch(
      "https://mass-onboarding.foqus-portal.live/download-template-edu",
      {
        method: "GET",
      }
    );

    const reader = response.body.getReader();
    const chunks = [];

    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        break;
      } else {
        chunks.push(value);
      }
    }
    const blob = new Blob(chunks, {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "Edu template.xlsx";

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  } catch (e) {
    console.error(e);
  }
});

export const getPotentialEmpEduFx = createEffect().use(async () => {
  try {
    const response = await fetch(
      "https://5zbcsztftk.execute-api.eu-west-1.amazonaws.com/default/GetPotentialEmployeesByPlaceId",
      {
        method: "POST",
        body: JSON.stringify({
          client_id: "zasa05e1p1",
          type: "potential_employees",
        }),
      }
    );

    let result = await response.json();
    return result.data;
  } catch (e) {
    console.error(e);
  }
});

export const getTemplateFx = createEffect().use(async () => {
  try {
    const response = await fetch(
      "https://mass-onboarding.foqus-portal.live/download-template",
      {
        method: "GET",
      }
    );

    const reader = response.body.getReader();
    const chunks = [];

    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        break;
      } else {
        chunks.push(value);
      }
    }
    const blob = new Blob(chunks, {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "Template.xlsx";

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  } catch (e) {
    console.error(e);
  }
});
