import { createBrowserRouter } from "react-router-dom";
import App from "../App.js";
import Dashboard from "../pages/Dashboard/Dashboard";
import DemoVideo from "../pages/DemoVideo/DemoVideo";
import Testing from "../pages/Testing/Testing";
import TestingClient from "../pages/TestingClient/TestingClient";
import UnrecognizedTesting from "../pages/UnrecognizedTesting/UnrecognizedTesting";
import SearchById from "../pages/SearchById/SearchById";
import FaceRec from "../pages/FaceRec/FaceRec";
import Registration from "../pages/Registration/Registration";
import DashboardHR from "../pages/DashboardHR/DashboardHR";
import Login from "../pages/CustomLogin/CustomLogin";
import Duplicates from "../pages/Duplicates/Duplicates";
import UserList from "../pages/UserList/UserList";
import DemoDashboard from "../pages/DemoDashboard/DemoDashboard";
import ClientUserList from "../pages/ClientUserList/ClientUserList";
import ClientUserListRefactor from "../pages/ClientUserList/ClientUserListRefactor";
import ClientUserListPotential from "../pages/ClientUserList/ClientUserListPotential";
import SchoolUserList from "../pages/SchoolUserList/SchoolUserList";
import BlackList from "../pages/BlackList/BlackList";
import BadImages from "../pages/BadImages/BadImages";
import QuickSightQ from '../pages/QuickSightQ/QuickSightQ';
import PortalUsers from "../pages/PortalUsers/PortalUsers";
import SchoolUserListRestyle from "../pages/SchoolUserListRestyle/SchoolUserListRestyle";
import SchoolStaffList from "../pages/SchoolStaffList/SchoolUserListRestyle";
import SchoolPotentialList from "../pages/SchoolPotentialList/SchoolUserListRestyle";

import { useUnit } from "effector-react";
import { $userGroups } from "../models/userModel";
import { useOutletContext } from "react-router-dom";




const SchoolUserListRestyleWrapper = () => {
  const { userGroups, isUserGroupsLoaded } = useOutletContext();

  if (!isUserGroupsLoaded) {
    return <div>Loading user groups...</div>;
  }

  return <SchoolUserListRestyle userGroups={userGroups} />;
};

const SchoolStaffListWrapper = () => {
  const { userGroups, isUserGroupsLoaded } = useOutletContext();

  if (!isUserGroupsLoaded) {
    return <div>Loading user groups...</div>;
  }

  return <SchoolStaffList userGroups={userGroups} />;
};

const routes = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      { path: "/dashboard", element: <Dashboard /> },
      { path: "/", element: <DemoDashboard /> },
      { path: "/login", element: <Login /> },
      // { path: '/', element: <Dashboard /> },
      { path: "/demo-video", element: <DemoVideo /> },
      { path: "/testing", element: <Testing /> },
      { path: "/testing-client", element: <TestingClient /> },
      { path: "/unrecognized-testing", element: <UnrecognizedTesting /> },
      { path: "/search-by-id", element: <SearchById /> },
      { path: "/face-rec", element: <FaceRec /> },
      { path: "/registration", element: <Registration /> },
      { path: "/hr-event", element: <DashboardHR /> },
      { path: "/demo-dashboard", element: <DemoDashboard /> },
      // { path: '/client-card', element: <ClientCard /> },
      // { path: '/partner-card', element: <PartnerCard /> },
      { path: "/duplicates", element: <Duplicates /> },
      { path: "/user-list", element: <UserList /> },
      { path: "/client-user-list", element: <ClientUserList /> },
      { path: "/client-user-list-refactor", element: <ClientUserListRefactor /> },
      { path: "/client-user-list-potential", element: <ClientUserListPotential /> },
      { path: "/user-list-edu", element: <SchoolUserList /> },
      // { path: "/user-list-edu-restyle", element: <SchoolUserListRestyle /> },
      { path: "/user-list-edu-restyle", element: <SchoolUserListRestyleWrapper /> },

      { path: "/school-staff-list", element: <SchoolStaffListWrapper /> },

      { path: "/school-potential-list", element: <SchoolPotentialList /> },
      { path: "/black-list", element: <BlackList /> },
      { path: "/bad-images", element: <BadImages /> },
      { path: "/quicksight-q", element: <QuickSightQ /> },
      { path: "/portal-users", element: <PortalUsers />}
    ],
  },
  // {
  //   path: '/login',
  //   element: <Login />,
  // },
  // {
  //   path: '/register',
  //   element: <Register />,
  // },
]);

export default routes;


//FIXME LAMBDA BACKUP
// import boto3
// import json
//   from os import environ
//
// def lambda_handler(event, context):
// quicksight_client = boto3.client('quicksight')
// response = quicksight_client.generate_embed_url_for_registered_user(
//   AwsAccountId='411274530068',
//   ExperienceConfiguration={
//     'QSearchBar': {
//       'InitialTopicId': 'RvK0Yke5mekh0qzRCBNS4dgZxQBZjNxo'
//     }
//   },
//   UserArn='arn:aws:quicksight:eu-west-1:411274530068:user/default/AWSReservedSSO_ops-data-eng-and-cv-perm-set_fc76eb39f7fff4f0/alikhan',
//   AllowedDomains=['https://www.foqus-portal.live', 'http://localhost:3000'],
//   SessionLifetimeInMinutes=600
// )
// return {
//   'statusCode': 200,
//   'embedUrl': response['EmbedUrl']
// }
